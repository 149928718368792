import Vue from 'vue';
import FloatingVue from 'floating-vue';
import PanZoom from 'vue-panzoom';
import { Icon, Modal, ActionLink, ActionButton } from 'fourwaves-shared/components';

// Plugins
Vue.use(FloatingVue);
Vue.use(PanZoom, { componentName: 'PanZoom' });

// Common components
Vue.component('Icon', Icon);
Vue.component('Modal', Modal);
Vue.component('ActionLink', ActionLink);
Vue.component('ActionButton', ActionButton);
