
import { Component, Vue } from 'nuxt-property-decorator';

const cookieName = 'ignore_browser_check';

@Component({})
export default class BrowserWarning extends Vue {
  isVisible = false;

  get updateBrowserLink(): string {
    return `https://browser-update.org/${this.$i18n.locale}/update-browser.html`;
  }

  beforeMount() {
    if (this.$cookies.get(cookieName)) return;
    this.isVisible = this.isMSIE() || this.isUnsupportedVersion();
  }

  public isMSIE(): boolean {
    const docStyleDeclaration = Array.from(window.getComputedStyle(document.documentElement, '')).join('');
    return /-ms-/.test(docStyleDeclaration);
  }

  public isUnsupportedVersion() {
    const { CSS } = window;
    return !CSS || !CSS.supports('width', 'clamp(1rem, 10vw, 2rem)');
  }

  public onDismiss() {
    this.$cookies.set(cookieName, true, { expires: this.$moment(new Date()).add(3, 'days').toDate() });
    this.isVisible = false;
  }
}
