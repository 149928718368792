
import { Component, Emit, Vue, namespace } from 'nuxt-property-decorator';
import { ScheduleDto } from 'fourwaves-shared';
import { UserProfilePicture, CalendarDateIcon } from 'fourwaves-shared/components';
import LangChooser from './LangChooser.vue';
import { LocaleLink } from '~/components';

const EventsModule = namespace('events');
const DirectMessagingModule = namespace('direct-messaging');

@Component({
  components: { LocaleLink, LangChooser, UserProfilePicture, CalendarDateIcon },
})
export default class MobileNavPanel extends Vue {
  @EventsModule.Getter isEventMultiLanguage!: boolean;
  @EventsModule.State sessions!: ScheduleDto[];
  @DirectMessagingModule.Getter threadCount!: number;
  @DirectMessagingModule.Getter unreadThreadCount!: number;

  @Emit() closePanel() {}

  get hasPosterHall() {
    return this.sessions.some(session => session.isVPS);
  }

  public onClickMessages() {
    this.$bus.emit('dm:modal-open');
    this.closePanel();
  }
}
