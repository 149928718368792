export default {
  confirm_quit: 'You are currently on a virtual call, do you really want to quit?',
  video_call_request_title: 'Conversation request',
  video_call_request_join: 'Join',
  video_call_request_decline: 'Decline',
  conversation: {
    browser_limitation: `The browser you're using ({browser}) is not compatible.`,
    zoom_browser_limitation:
      'Mobile browsers are not fully supported for zoom calls and might result in a poor experience.',
  },
};
