
import { Component, Ref, Watch, Vue, namespace } from 'nuxt-property-decorator';
import { HubConnection } from '@microsoft/signalr';
import { SettingsDto } from 'fourwaves-shared';
import DirectMessagingThreads from './DirectMessagingThreads.vue';
import DirectMessagingChat from './DirectMessagingChat.vue';
import { DirectMessaging as DM } from '~/types';

const DirectMessagingModule = namespace('direct-messaging');

@Component({
  components: {
    DirectMessagingThreads,
    DirectMessagingChat,
  },
})
export default class DirectMessaging extends Vue {
  @DirectMessagingModule.State readonly isConnected!: boolean;
  @DirectMessagingModule.State readonly currentThreadId!: string | null;
  @DirectMessagingModule.State readonly provisionalThread!: DM.ProvisionalThread | null;
  @DirectMessagingModule.State readonly threads!: Record<string, DM.ThreadDto>;
  @DirectMessagingModule.Getter readonly unreadThreadCount!: number;
  @DirectMessagingModule.Getter readonly getThreadByUserId!: (userId: string) => DM.ThreadDto | null;
  @DirectMessagingModule.Action(DM.Action.Init) directMessagingInit!: (connection: HubConnection) => void;
  @DirectMessagingModule.Mutation(DM.Mutation.SetCurrentThreadId) setCurrentThreadId!: (id: string | null) => void;
  @DirectMessagingModule.Mutation(DM.Mutation.ClearProvisionalThread) clearProvisionalThread!: () => void;
  @DirectMessagingModule.Mutation(DM.Mutation.CreateProvisionalThread) createProvisionalThread!: (
    users: DM.User[],
  ) => void;

  @Ref() dmModalNode?: HTMLDivElement;

  isOpen = false;

  get currentThreadUserId() {
    if (!this.currentUser) return null;
    const thread = this.provisionalThread || this.threads[this.currentThreadId ?? ''];
    return thread ? thread.users.find(({ id }) => id !== this.currentUser.id)?.id || null : null;
  }

  get currentUser() {
    return this.$auth.user as SettingsDto;
  }

  @Watch('isOpen')
  onOpenStateChanged(isOpen: boolean) {
    setTimeout(async () => {
      await this.$nextTick();
      if (isOpen) this.dmModalNode?.addEventListener('click', this.onClickBody);
    });
  }

  created() {
    this.$bus.on('dm:modal-open', this.openDirectMessaging);
    if (!this.isConnected) this.directMessagingInit(this.$realtime);
  }

  beforeDestroy() {
    this.dmModalNode?.removeEventListener('click', this.onClickBody);
    this.$bus.off('dm:modal-open', this.openDirectMessaging);
  }

  public openDirectMessaging(withUser?: DM.User) {
    if (withUser && this.currentUser?.id === withUser.id) return;

    if (withUser) {
      const existingUserThread = this.getThreadByUserId(withUser.id);
      if (existingUserThread) this.setCurrentThreadId(existingUserThread.id);
      else this.createProvisionalThread([this.currentUser, withUser]);
    }

    this.isOpen = true;
  }

  public closeDirectMessaging() {
    this.isOpen = false;
    if (this.provisionalThread) this.clearProvisionalThread();
  }

  public onClickBody() {
    this.$bus.emit('dm:close-reaction-pickers');
  }

  public onClickBack() {
    if (!this.currentThreadId) this.closeDirectMessaging();
    else this.setCurrentThreadId(null);
  }
}
