import { MutationTree } from 'vuex';

export interface LiveState {
  hasUnseenActivityFeedEntries: boolean;
  hasUnseenLiveChatMessages: boolean;
}

export const getDefaultLiveState = (): LiveState => ({
  hasUnseenActivityFeedEntries: false,
  hasUnseenLiveChatMessages: false,
});

export const state = (): LiveState => getDefaultLiveState();

export const getters = {
  hasUnseenNotifications: (state: LiveState) => state.hasUnseenActivityFeedEntries || state.hasUnseenLiveChatMessages,
};

export const mutations: MutationTree<LiveState> = {
  setHasUnseenActivityFeedEntries(state: LiveState, value: boolean): void {
    state.hasUnseenActivityFeedEntries = value;
  },
  setHasUnseenLiveChatMessages(state: LiveState, value: boolean): void {
    state.hasUnseenLiveChatMessages = value;
  },
};
