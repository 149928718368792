import { BaseChannel, BaseChannelParams } from 'fourwaves-shared';

export type OnlineUserDto = {
  id: string;
  participantId?: string | null;
  firstName: string;
  lastName: string;
  timestamp: string;
};

type ParticipantsModuleChannelParams = BaseChannelParams & {
  eventId: string;
  onOnlineUserCountUpdatedCallback: (count: number) => void;
  onMostRecentOnlineUsersUpdatedCallback: (users: OnlineUserDto[]) => void;
};

export class ParticipantsModuleChannel extends BaseChannel {
  private eventId: string;
  private onOnlineUserCountUpdatedCallback: (count: number) => void;
  private onMostRecentOnlineUsersUpdatedCallback: (users: OnlineUserDto[]) => void;

  private get onlineUserCountChannel(): string {
    return `event.online_user_count/${this.eventId}`;
  }

  private get mostRecentOnlineUsers(): string {
    return `event.most_recent_users/${this.eventId}`;
  }

  constructor(params: ParticipantsModuleChannelParams) {
    super(params.connection);
    this.eventId = params.eventId;
    this.onOnlineUserCountUpdatedCallback = params.onOnlineUserCountUpdatedCallback;
    this.onMostRecentOnlineUsersUpdatedCallback = params.onMostRecentOnlineUsersUpdatedCallback;
  }

  subscribe() {
    if (this.isSubscribed) return;
    this.connection.on(this.onlineUserCountChannel, this.onOnlineUserCountUpdatedCallback);
    this.connection.invoke('Subscribe', this.onlineUserCountChannel);
    this.connection.on(this.mostRecentOnlineUsers, this.onMostRecentOnlineUsersUpdatedCallback);
    this.connection.invoke('Subscribe', this.mostRecentOnlineUsers);
    this.isSubscribed = true;
  }

  unsubscribe() {
    if (!this.isSubscribed) return;
    this.connection.off(this.onlineUserCountChannel, this.onOnlineUserCountUpdatedCallback);
    this.connection.invoke('Unsubscribe', this.onlineUserCountChannel);
    this.connection.off(this.mostRecentOnlineUsers, this.onMostRecentOnlineUsersUpdatedCallback);
    this.connection.invoke('Unsubscribe', this.mostRecentOnlineUsers);
    this.isSubscribed = false;
  }
}
