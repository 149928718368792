export default {
  title: 'Schedule',
  no_schedule: 'There are no sessions to show.',
  time_zone_details: '* All times are based on',
  types: {
    postersession: 'Poster session',
    keynote: 'Keynote',
    oral: 'Oral',
    panel: 'Panel',
    workshop: 'Workshop',
    networking: 'Networking',
    break: 'Break',
    other: 'Other',
  },
  sign_to_access_room: 'Log in to access room',
  register_to_access_room: 'Register to access room',
  back_to_list: 'Back',
  only_participant_can_access_rooms: 'Only registered participants can access the room',
  live: 'Live',
  now: 'Now',
  virtual: 'Virtual',
  minute_countdown: 'In {count} min',
  hour_countdown: 'In {count} hour | In {count} hours',
  day_countdown: 'In {count} day | In {count} days',
  tomorrow: 'Tomorrow',
  cta_poster: 'Go to Poster Hall',
  cta_livestream: 'Join',
  presentation: {
    title: 'Presentations',
    show_all_in_presentations: 'View all in the presentations page',
    no_results: 'There are no presentations in this session.',
    presenting_now: 'Presenting now',
    presented_by: 'Presented by ',
  },
  bookmark: 'Bookmark',
  unbookmark: 'Unbookmark',
  bookmarked: 'Bookmarked',
  filter_bookmark: 'Bookmarked',
  session_count: 'No sessions | {count} session | {count} sessions',
  parallel_sessions: '{count} parallel sessions',
};
