import { Vue } from 'nuxt-property-decorator';

type EventBusHandler = (...payload: any[]) => void;

const eventBusVm = new Vue();

export const eventBus = {
  on(eventName: string, handler: EventBusHandler) {
    eventBusVm.$on(eventName, handler);
  },
  off(eventName: string, handler: EventBusHandler) {
    eventBusVm.$off(eventName, handler);
  },
  once(eventName: string, handler: EventBusHandler) {
    eventBusVm.$once(eventName, handler);
  },
  emit(eventName: string, ...payload: any[]) {
    eventBusVm.$emit(eventName, ...payload);
  },
};

export default (_, inject) => inject('bus', eventBus);
