import locale6f270a0b from '../../src/locales/en/index.ts'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"name":"English","code":"en","iso":"en-CA","file":"en/index.ts"},{"name":"Français","code":"fr","iso":"fr-CA","file":"fr/index.ts"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "~/locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":".fourwaves.com","cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"en","redirectOn":"all","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"English","code":"en","iso":"en-CA","file":"en/index.ts"},{"name":"Français","code":"fr","iso":"fr-CA","file":"fr/index.ts"}],
  localeCodes: ["en","fr"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "/",
  5: "i",
  6: "n",
  7: "d",
  8: "e",
  9: "x",
  10: ".",
  11: "t",
  12: "s",
  13: "\"",
  14: ":",
  15: "\"",
  16: ".",
  17: ".",
  18: "/",
  19: "s",
  20: "r",
  21: "c",
  22: "/",
  23: "l",
  24: "o",
  25: "c",
  26: "a",
  27: "l",
  28: "e",
  29: "s",
  30: "/",
  31: "e",
  32: "n",
  33: "/",
  34: "i",
  35: "n",
  36: "d",
  37: "e",
  38: "x",
  39: ".",
  40: "t",
  41: "s",
  42: "\"",
  43: ",",
  44: "\"",
  45: "f",
  46: "r",
  47: "/",
  48: "i",
  49: "n",
  50: "d",
  51: "e",
  52: "x",
  53: ".",
  54: "t",
  55: "s",
  56: "\"",
  57: ":",
  58: "\"",
  59: ".",
  60: ".",
  61: "/",
  62: "s",
  63: "r",
  64: "c",
  65: "/",
  66: "l",
  67: "o",
  68: "c",
  69: "a",
  70: "l",
  71: "e",
  72: "s",
  73: "/",
  74: "f",
  75: "r",
  76: "/",
  77: "i",
  78: "n",
  79: "d",
  80: "e",
  81: "x",
  82: ".",
  83: "t",
  84: "s",
  85: "\"",
  86: "}",
}

export const localeMessages = {
  'en/index.ts': () => Promise.resolve(locale6f270a0b),
  'fr/index.ts': () => import('../../src/locales/fr/index.ts' /* webpackChunkName: "lang-fr/index.ts" */),
}
