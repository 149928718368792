import { Vue } from 'nuxt-property-decorator';

export default () => {
  Vue.mixin({
    mounted() {
      if (this.$options.sidePanel == null) return;
      this.$store.commit('events/setSidePanelState', this.$options.sidePanel);
    },
  });
};
