
import { Component, Vue, Prop, Inject } from 'nuxt-property-decorator';
import { isSuccess } from '../../services';
import BaseMessagingComponent from './BaseMessagingComponent';

@Component({})
export default class LiveMessageInput extends Vue {
  @Inject() $validator;
  @Prop({ required: true }) formEntryId!: string;
  @Prop({ required: true }) placeHolder!: string;
  @Prop({ required: true }) maxLength!: number;
  @Prop(Boolean) isDarkMode!: boolean;

  uniqueRefBtn = Math.random();
  uniqueRefTextArea = Math.random();
  messageText = '';

  public async createMessage() {
    if (await this.$validator.validateAll()) {
      const response = await (this.$parent as BaseMessagingComponent).createMessage(this.messageText);

      if (isSuccess(response)) {
        this.onMessageSent();
      }
    }
  }

  public async handleCmdEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await this.createMessage();
    }
  }

  public onMessageSent() {
    this.messageText = '';

    // add input focus
    if (!this.$refs[this.uniqueRefTextArea]) return;
    if (this.$refs[this.uniqueRefTextArea]) {
      (this.$refs[this.uniqueRefTextArea] as HTMLTextAreaElement).focus();
    }
  }
}
