import { Component, Vue } from 'nuxt-property-decorator';
import { DirectMessaging as DM } from '~/types';

@Component
export default class DirectMessagingMixin extends Vue {
  public getInterlocutorUser(users: DM.User[]) {
    const user = users.find(({ id }) => id !== this.$auth.user.id);
    return user ? { userId: user.id, firstName: user.firstName, lastName: user.lastName } : null;
  }

  public getInterlocutorFullName(users: DM.User[]) {
    const user = users.find(({ id }) => id !== this.$auth.user.id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }
}
