import { initiateWebSocket } from '~/services';
import { EventsState } from '~/types/state';

export default async function ({ $config, $cookies, $auth, store }, inject) {
  if ($auth?.loggedIn) {
    const eventId = (store.state.events as EventsState).eventId;
    inject('realtime', await initiateWebSocket($config, $cookies.get('auth._token.local'), eventId));
  } else {
    inject('realtime', null);
  }
}
