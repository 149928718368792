export default {
  title: {
    401: 'Login to see this page',
    403: 'Restricted Content',
    404: 'Page not found',
    default: 'An error occured',
  },
  message: {
    401: 'This page is visible to event participants only. Please <a href="{url}" data-test-id="js-login">login</a> using the email address used during registration.',
    403: 'This page is only visible to registered participants.',
    404: 'It seems like this page does not exist anymore. Return to <a href="{url}">Home page</a>.',
    default: 'Return to <a href="{url}">Home page</a>.',
  },
};
