
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';

@Component({})
export default class AnimatedNumber extends Vue {
  @Prop({ required: true }) value!: number;
  @Prop({ default: 500 }) speed!: number;

  currentValue: number | null = null;
  newValue: number | null = null;
  isAnimating = false;
  increase = true;

  get transitionDuration() {
    return `transitionDuration: ${this.speed}ms`;
  }

  get newValueTransitionName(): string {
    return this.increase ? 'v-number-new-value-is-bigger' : 'v-number-new-value-is-lower';
  }

  get currentValueTransitionName(): string {
    return !this.increase ? 'v-number-current-value-is-bigger' : 'v-number-current-value-is-lower';
  }

  created() {
    this.currentValue = this.value;
  }

  @Watch('value')
  onValueChanged(changedValue) {
    if (this.isAnimating) return;
    this.switchDirection(changedValue);
    this.isAnimating = true;
    this.currentValue = null;
    this.newValue = changedValue;
    setTimeout(() => {
      this.currentValue = changedValue;
      this.newValue = null;
      this.isAnimating = false;
    }, this.speed);
  }

  public switchDirection(changedValue) {
    if (!this.currentValue) return;
    this.increase = !!(changedValue > this.currentValue);
  }
}
