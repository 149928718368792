
import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator';
import { BurgerBtn } from 'fourwaves-shared/components';
import FourwavesLogoSquare from 'fourwaves-shared/src/images/fourwaves-light-sq.png';
import MobileNavPanel from './MobileNavPanel.vue';
import LiveSidePanel from './LiveSidePanel.vue';
import LocaleLink from '~/components/common/LocaleLink.vue';

const LiveModule = namespace('live');
const DirectMessagingModule = namespace('direct-messaging');

@Component({
  components: { LocaleLink, MobileNavPanel, LiveSidePanel, BurgerBtn },
})
export default class MobileNav extends Vue {
  @LiveModule.Getter hasUnseenNotifications!: boolean;
  @DirectMessagingModule.Getter hasUnreadMessages!: boolean;

  @Prop(Boolean) readonly isLiveSidePanelOpen!: boolean;

  readonly FourwavesLogoSquare = FourwavesLogoSquare;

  isNavPanelOpen = false;

  public toggleNavPanel() {
    if (this.isLiveSidePanelOpen) this.$emit('toggle-live-side-panel');
    this.isNavPanelOpen = !this.isNavPanelOpen;
  }

  public toggleLiveSidePanel() {
    if (this.isNavPanelOpen) this.isNavPanelOpen = false;
    this.$emit('toggle-live-side-panel');
  }
}
