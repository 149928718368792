export default {
  title: 'Participants',
  participant: 'No participants | {count} participant | {count} participants',
  bookmark_filter: 'My connections',
  online_filter: 'Online',
  no_results: 'There are no participants to show',
  back_to_list: 'Back',
  placeholder_text: 'No information available.',
  listing_options: {
    alphabetical: 'A - Z',
  },
  presenting_now: 'Presenting now',
  schedule_presentations: {
    title: 'Scheduled in {count} session | Scheduled in {count} sessions',
  },
};
