import { BaseChannel } from 'fourwaves-shared';
import type { BaseChannelParams } from 'fourwaves-shared';
import type { DirectMessaging } from '~/types/state/direct-messaging';

type OnSyncCallbackFn = (threads: DirectMessaging.ThreadDto[]) => void;
type OnThreadAddedCallbackFn = (thread: DirectMessaging.ThreadDto) => void;
type OnThreadUpdatedCallbackFn = (thread: DirectMessaging.ThreadDto) => void;

export type ThreadsChannelParams = BaseChannelParams & {
  onSyncCallback: OnSyncCallbackFn;
  onThreadAddedCallback: OnThreadAddedCallbackFn;
  onThreadUpdatedCallback: OnThreadUpdatedCallbackFn;
};

export class ThreadsChannel extends BaseChannel {
  constructor(params: ThreadsChannelParams) {
    super(params.connection);
    this.connection.on('DirectMessagingSync', params.onSyncCallback);
    this.connection.on('ThreadAdded', params.onThreadAddedCallback);
    this.connection.on('ThreadUpdated', params.onThreadUpdatedCallback);
    this.connection.invoke('SynchronizeDirectMessaging');
    this.connection.onreconnected(() => this.connection?.invoke('SynchronizeDirectMessaging'));
  }
}
