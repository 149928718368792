const OT = () => import('@opentok/client');

export async function checkScreenSharingCapability(): Promise<OT.ScreenSharingCapabilityResponse> {
  return await new Promise(resolve => {
    OT().then(ot =>
      ot.checkScreenSharingCapability(response => {
        resolve(response);
      }),
    );
  });
}
