
import { Component, Watch, Vue, namespace } from 'nuxt-property-decorator';
import { Route } from 'vue-router';
import { EventDto, transl8 } from 'fourwaves-shared';
import { Spinner } from 'fourwaves-shared/components';
import {
  BrowserWarning,
  MainNav,
  LiveSidePanel,
  MobileNav,
  ScrollContext,
  SplashScreen,
  DirectMessaging,
} from '~/components';

const EventsModule = namespace('events');
const HistoryModule = namespace('history');

@Component({
  components: {
    BrowserWarning,
    MainNav,
    MobileNav,
    LiveSidePanel,
    Spinner,
    ScrollContext,
    SplashScreen,
    DirectMessaging,
  },
})
export default class DefaultLayout extends Vue {
  @HistoryModule.Mutation setPreviousRoute!: (route: Route | null) => void;

  @EventsModule.State currentEvent!: EventDto;

  isMobileLiveSidePanelOpen = false;
  isDirectMessagingOpen = false;
  isSplashComplete = false;

  get pageTransitionKey() {
    return this.$route.path.split('/').slice(0, 4).join('/');
  }

  @Watch('$route', { immediate: true })
  onRouteChanged(_: Route, previousRoute?: Route | null) {
    this.setPreviousRoute(previousRoute || null);
  }

  @Watch('$route.path')
  onRoutePathChanged() {
    if (this.$device.laptop || !this.isMobileLiveSidePanelOpen) return;
    this.isMobileLiveSidePanelOpen = false;
  }

  created() {
    this.$moment.locale(this.$i18n.locale);
    this.$moment.tz.setDefault(this.$moment.tz.guess());

    if (this.$route.query.email) {
      const { email, ...query } = this.$route.query;

      // when the user is coming from an email and must be logged in
      if (!this.$auth.loggedIn) {
        const loginUrl = this.getLoginUrl(email as string);
        return window.location.assign(loginUrl);
      }

      this.$router.replace({ query });
    }
  }

  public head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      title: this.currentEvent ? transl8(this.currentEvent.name) : '',
      meta: this.currentEvent?.isPrivate ? [{ hid: 'robots', name: 'robots', content: 'noindex' }] : [],
      link: [
        {
          rel: 'canonical',
          href: this.$route.path?.endsWith('/pages')
            ? `${location.protocol}//${location.host}${this.$route?.path.replace('/pages', '')}`
            : `${location.protocol}//${location.host}${this.$route?.path}`,
        },
      ],
    };
  }

  public toggleSidePanel() {
    this.isMobileLiveSidePanelOpen = !this.isMobileLiveSidePanelOpen;
  }
}
