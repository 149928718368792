import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { orderBy } from 'lodash-es';
import { HubConnection } from '@microsoft/signalr';
import { ThreadsChannel } from '~/channels';
import { RootState, DirectMessaging as DM } from '~/types';

const lastThreadCookie = 'dm-thread';

export const state = (): DM.State => ({
  threads: {},
  channel: null,
  isConnected: false,
  currentThreadId: null,
  provisionalThread: null,
});

export const getters: GetterTree<DM.State, RootState> = {
  hasUnreadMessages({ threads }) {
    return Object.values(threads).some(({ unreadMessagesCount }) => unreadMessagesCount);
  },
  unreadThreadCount({ threads }) {
    return Object.values(threads).filter(({ unreadMessagesCount }) => unreadMessagesCount).length;
  },
  getThreadByUserId({ threads }) {
    return (userId: string) => Object.values(threads).find(({ users }) => users.some(({ id }) => userId === id));
  },
  orderedThreads({ threads }) {
    return orderBy(Object.values(threads), [({ lastUpdateDate }) => lastUpdateDate], ['desc']);
  },
  threadCount({ threads }) {
    return Object.keys(threads).length;
  },
};

export const mutations: MutationTree<DM.State> = {
  [DM.Mutation.SetConnectedState](state, isConnected) {
    state.isConnected = isConnected;
  },

  [DM.Mutation.SetCurrentThreadId](state, threadId: string | null) {
    state.currentThreadId = threadId;
    if (state.provisionalThread) state.provisionalThread = null;
    if (!this.$device.tablet) return;
    const expires = this.$moment().add(1, 'year').toDate();

    const cookieParams = this.$config.API_URL?.includes('localhost')
      ? { domain: 'localhost', path: '/', expires }
      : { domain: '.fourwaves.com', path: '/', expires, sameSite: 'none', secure: true };

    this.$cookies.set(lastThreadCookie, threadId, cookieParams);
  },

  [DM.Mutation.SetThreads](state, threads: DM.ThreadDto[]) {
    state.threads = threads.reduce((threadList, thread) => ({ ...threadList, [thread.id]: thread }), {});
  },

  [DM.Mutation.AddThread](state, thread: DM.ThreadDto) {
    state.threads = { ...state.threads, [thread.id]: thread };
  },

  [DM.Mutation.PatchThread](state, thread: Partial<DM.ThreadDto> & { id: string }) {
    if (state.threads[thread.id]) state.threads[thread.id] = { ...state.threads[thread.id], ...thread };
  },

  [DM.Mutation.CreateProvisionalThread](state, users: DM.User[]) {
    state.provisionalThread = { users, preview: '' };
  },

  [DM.Mutation.ClearProvisionalThread](state) {
    state.provisionalThread = null;
  },

  [DM.Mutation.PatchProvisionalThread](state, thread: Partial<DM.ProvisionalThread>) {
    if (state.provisionalThread) state.provisionalThread = { ...state.provisionalThread, ...thread };
  },
};

export const actions: ActionTree<DM.State, RootState> = {
  [DM.Action.Init]({ commit, dispatch }, connection: HubConnection) {
    const channel = new ThreadsChannel({
      connection,
      onSyncCallback: (threads: DM.ThreadDto[]) => {
        commit(DM.Mutation.SetThreads, threads);
        commit(DM.Mutation.SetConnectedState, true);
      },
      onThreadAddedCallback(thread: DM.ThreadDto) {
        dispatch(DM.Action.OnThreadAdded, thread);
      },
      onThreadUpdatedCallback(thread: DM.ThreadDto) {
        commit(DM.Mutation.PatchThread, thread);
      },
    });

    if (this.$device.tablet) commit(DM.Mutation.SetCurrentThreadId, this.$cookies.get(lastThreadCookie) || null);
    return channel;
  },

  [DM.Action.OnThreadAdded]({ state, commit }, thread: DM.ThreadDto) {
    commit(DM.Mutation.AddThread, thread);
    // if the provisional thread is waiting for this thread, we switch to it
    if (state.provisionalThread?.id === thread.id) commit(DM.Mutation.SetCurrentThreadId, thread.id);
  },

  [DM.Action.SetProvisionalThreadId]({ state, commit }, id: string) {
    // if the thread has already been received, we switch to the thread instead of updating provisional
    if (state.threads[id]) commit(DM.Mutation.SetCurrentThreadId, id);
    else commit(DM.Mutation.PatchProvisionalThread, { id });
  },
};
