import { AxiosError } from 'axios';
import { ZoomEmbedDto, TypeOrError, BaseApiService } from 'fourwaves-shared';

export interface IApiService extends BaseApiService {
  // upvotes
  upvoteFormEntry(formEntryId: string): Promise<TypeOrError<boolean>>;
  deleteFormEntryUpvote(formEntryId: string): Promise<TypeOrError<boolean>>;

  // bookmarks
  bookmarkFormEntry(formEntryId: string): Promise<TypeOrError<boolean>>;
  unbookmarkFormEntry(formEntryId: string): Promise<TypeOrError<boolean>>;

  // zoom
  getZoomEmbedInfo(roomId: string): Promise<ZoomEmbedDto>;
}

export class ApiService extends BaseApiService implements IApiService {
  public async upvoteFormEntry(formEntryId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/form-entries/${formEntryId}/upvote`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteFormEntryUpvote(formEntryId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/form-entries/${formEntryId}/upvote`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async bookmarkFormEntry(formEntryId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/form-entries/${formEntryId}/bookmark`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async unbookmarkFormEntry(formEntryId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/form-entries/${formEntryId}/bookmark`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getZoomEmbedInfo(roomId: string): Promise<ZoomEmbedDto> {
    const url = `/api/sessions/rooms/${roomId}/zoom-signature`;
    const response = await this._client.get<ZoomEmbedDto>(url);
    return response.data;
  }
}
