
import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator';
import type { ScheduleDto } from 'fourwaves-shared';
import { UserProfilePicture, CalendarDateIcon } from 'fourwaves-shared/components';
import FourwavesLogoSquare from 'fourwaves-shared/src/images/fourwaves-light-sq.png';
import LangChooser from './LangChooser.vue';
import LocaleLink from '~/components/common/LocaleLink.vue';

const DirectMessagingModule = namespace('direct-messaging');
const EventsModule = namespace('events');

@Component({
  components: { LocaleLink, LangChooser, UserProfilePicture, CalendarDateIcon },
})
export default class MainNav extends Vue {
  @EventsModule.Getter isEventMultiLanguage!: boolean;
  @EventsModule.State sessions!: ScheduleDto[];
  @DirectMessagingModule.Getter threadCount!: number;
  @DirectMessagingModule.Getter hasUnreadMessages!: boolean;

  @Prop(Boolean) readonly isDirectMessagingOpen!: boolean;

  readonly FourwavesLogoSquare = FourwavesLogoSquare;

  get hasPosterHall() {
    return this.sessions.some(session => session.isVPS);
  }

  public onClickMessages() {
    this.$bus.emit('dm:modal-open');
  }
}
