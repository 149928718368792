export default {
  title: 'Dashboard',
  learn_more: 'Learn More',
  learn_more_aria: 'Learn more about',
  greetings: {
    hello: 'Hello',
    agenda: 'Your agenda',
    bookmarked: 'Bookmarked',
    connections: 'Connection | Connections',
    external_title: 'Go to Event Website',
  },
  sessions: {
    title: 'Sessions',
    live: 'Live now',
    aria_list: 'View Sessions List',
    aria_online: 'View {count} live session',
    join: 'Join',
    cta: 'View Schedule',
    cta_minicard_aria: 'View session {name} ',
  },
  participants: {
    title: 'Participants',
    online_now: 'online now',
    logged_in: 'Connected {time}',
    aria_list: 'View Participants List',
    aria_online: 'View {count} online participants',
    greeting: 'Say Hi',
    cta: 'View Participants',
    cta_minicard_aria: 'Send a message to {name}',
  },
  presentations: {
    title: 'Presentations',
    aria_list: 'View Presentations List',
    aria_online: 'View {count} live presentations',
    view: 'View',
    by: 'by',
    cta: 'View Presentations',
  },
  poster_hall: {
    title: 'Poster Hall',
    cta: 'Go to Poster Hall',
    aria_list: 'View Posters List',
    aria_live: 'View live posters',
    next_aria: 'See next poster',
    prev_aria: 'See previous poster',
  },
};
