
import { Component, mixins, namespace } from 'nuxt-property-decorator';
import { InputTextSearch } from 'fourwaves-shared/components';
import DirectMessagingMixin from './DirectMessagingMixin';
import DirectMessagingThreadEntry from './DirectMessagingThreadEntry.vue';
import { ScrollContext } from '~/components';
import { DirectMessaging as DM } from '~/types';

const DirectMessagingModule = namespace('direct-messaging');

@Component({
  components: {
    ScrollContext,
    InputTextSearch,
    DirectMessagingThreadEntry,
  },
})
export default class DirectMessagingThreads extends mixins(DirectMessagingMixin) {
  @DirectMessagingModule.State readonly currentThreadId!: string | null;
  @DirectMessagingModule.State readonly provisionalThread!: DM.ProvisionalThread | null;
  @DirectMessagingModule.Getter readonly orderedThreads!: DM.ThreadDto[];

  searchTerm = '';
  isScrolled = false;

  get filteredThreads() {
    if (!this.searchTerm.length) return this.orderedThreads;
    const searchTerm = this.searchTerm.toLowerCase();

    return this.orderedThreads.filter(thread =>
      this.getInterlocutorFullName(thread.users).toLowerCase().includes(searchTerm),
    );
  }

  public onScrolled(isScrolled: boolean) {
    this.isScrolled = isScrolled;
  }
}
