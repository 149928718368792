
import { Component, Prop, mixins, namespace } from 'nuxt-property-decorator';
import { UserProfilePicture } from 'fourwaves-shared/components';
import DirectMessagingMixin from './DirectMessagingMixin';
import { DirectMessaging as DM } from '~/types';

const DirectMessagingModule = namespace('direct-messaging');

@Component({
  components: {
    UserProfilePicture,
  },
})
export default class DirectMessagingThreadEntry extends mixins(DirectMessagingMixin) {
  @DirectMessagingModule.Mutation(DM.Mutation.SetCurrentThreadId) setCurrentThreadId!: (id: string | null) => void;

  @Prop({ type: Object, required: true }) readonly thread!: DM.ProvisionalThread;
  @Prop(Boolean) readonly isProvisional!: boolean;

  get preview() {
    return this.isProvisional ? this.$t('directMessaging.new_conversation') : this.thread.preview;
  }

  public setCurrentThread({ id }: DM.ProvisionalThread) {
    if (!id) return;
    this.setCurrentThreadId(id);
  }
}
