
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator';
import { TypeOrError, isSuccess } from 'fourwaves-shared';

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  directives: {
    focus: { inserted: el => el.focus() },
  },
})
export default class DirectMessagingChatInput extends Vue {
  @Prop({ required: true }) submitHandler!: (text: string) => Promise<TypeOrError<boolean>>;
  @Prop({ required: true }) maxLength!: number;
  @Prop({ default: '' }) placeholder!: string;

  @Ref() readonly inputNode?: HTMLTextAreaElement;

  messageText = '';
  isSubmitting = false;

  public async onSubmit() {
    const isValid = await this.$validator.validateAll();
    if (!isValid || this.isSubmitting) return;
    this.isSubmitting = true;
    const text = this.messageText;
    this.messageText = '';
    const result = await this.submitHandler(text.trim().replace(/\n{3,}/g, '\n\n'));
    if (!isSuccess(result)) this.messageText = text;
    this.isSubmitting = false;
    this.inputNode?.focus();
  }
}
