
import { Component, Vue, Prop, namespace, Watch } from 'nuxt-property-decorator';

const EventsModule = namespace('events');

@Component({})
export default class LangChooser extends Vue {
  @EventsModule.State language!: boolean;
  @Prop({ default: '' }) linkClasses!: string;
  @Prop(Boolean) shortLabel!: boolean;

  @Watch('language', { immediate: true })
  onEventLanguageChange(newLang: string) {
    if (newLang === 'french') {
      this.changeLanguage('fr');
    } else if (newLang === 'english') {
      this.changeLanguage('en');
    } else {
      // we keep the same language if "browser"
    }
  }

  get availableLocales() {
    return (this.$i18n.locales as Array<any>).filter(x => x.code !== this.$i18n.locale);
  }

  public changeLanguage(code: string) {
    this.$moment.locale(code);
    this.$i18n.setLocale(code);
    this.$i18n.setLocaleCookie(code);
    this.changeRoute(code);
  }

  public changeRoute(code: string) {
    this.$router.push(this.switchLocalePath(code));
  }

  public getLabel(localeName: string): string {
    return this.shortLabel ? localeName.substr(0, 2) : localeName;
  }
}
