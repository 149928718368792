
import { MessageThreadType } from 'fourwaves-shared';
import { Component, Vue, Ref, Prop, Watch, namespace } from 'nuxt-property-decorator';
import LiveChat from 'fourwaves-shared/src/components/messaging/LiveChat.vue';

const EventsModule = namespace('events');
const LiveModule = namespace('live');

@Component({ components: { LiveChat } })
export default class GlobalChat extends Vue {
  @Ref() readonly liveChat!: { scrollDown: () => '' };
  @EventsModule.State eventId!: string;
  @LiveModule.Mutation setHasUnseenLiveChatMessages!: (value: boolean) => void;

  @Prop({ type: Boolean, required: true }) readonly isDisplayed!: boolean;

  readonly type = MessageThreadType.LiveChat;

  @Watch('isDisplayed', { immediate: true })
  onDisplayChanged(isDisplayed: boolean) {
    if (!isDisplayed) return;
    this.setHasUnseenLiveChatMessages(false);
    this.liveChat?.scrollDown();
  }

  public onNewEntry() {
    if (!this.isDisplayed) this.setHasUnseenLiveChatMessages(true);
  }
}
