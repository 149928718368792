
import UserProfilePicture from 'fourwaves-shared/src/components/users/UserProfilePicture.vue';
import { ActivityFeedEntryDto } from 'fourwaves-shared';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { TimeFromNow } from 'fourwaves-shared/components';

@Component({
  components: { UserProfilePicture, TimeFromNow },
})
export default class ParticipantFollowed extends Vue {
  @Prop({ required: true }) entry!: ActivityFeedEntryDto;
}
