export default {
  loading: 'Loading data...',
  offline: 'Offline',
  online: 'Online',
  participant: 'participant | participants',
  poster: 'poster | posters',
  presentation: 'presentation | presentations',
  send: 'Send',
  session: 'session | sessions',
  view: 'View',
  nav: {
    account: 'Account',
    home: 'Home',
    lang_switch: 'Naviguer en',
    menu: 'Menu',
    messages: 'Messages',
    participants: 'Participants',
    posters: 'Poster Hall',
    presentations: 'Presentations',
    schedule: 'Schedule',
    toggle_live_panel: 'Toggle live panel',
    toggle_nav_aria: 'Toggle Navigation',
  },
  review_status: {
    completed: 'Completed',
    conflictofinterest: 'Conflict of interests',
    pending: 'Pending',
    title: 'To review',
    unassigned: 'Unassigned',
  },
};
