
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { PresentationElement } from 'fourwaves-shared/components';
import UserProfilePicture from 'fourwaves-shared/src/components/users/UserProfilePicture.vue';
import PosterCardViewer from './PosterCardViewer.vue';

@Component({
  components: {
    UserProfilePicture,
    PosterCardViewer,
  },
})
export default class PosterCard extends Vue {
  @Prop({ required: true }) readonly presentation!: PresentationElement;
  @Prop(Boolean) readonly isListDisplay!: boolean;
  @Prop(Boolean) readonly withViewers!: boolean;

  get presentationLink() {
    return this.presentation.thumbnail
      ? this.localePath({ name: 'slug-presentations-id-poster', params: { id: this.presentation.id } })
      : this.localePath({ name: 'slug-presentations-id', params: { id: this.presentation.id } });
  }
}
