
import { EventDto, FormDto } from 'fourwaves-shared';
import { Component, Vue, namespace } from 'nuxt-property-decorator';
import UserProfilePicture from 'fourwaves-shared/src/components/users/UserProfilePicture.vue';
import { AnimatedNumber } from '../common';
import { OnlineUserDto, ParticipantsModuleChannel } from '~/channels';

const EventsModule = namespace('events');

@Component({ components: { AnimatedNumber, UserProfilePicture } })
export default class ParticipantsModule extends Vue {
  @EventsModule.State eventId!: string;
  @EventsModule.State currentEvent!: EventDto;
  @EventsModule.State currentTime!: string;
  @EventsModule.Getter registrationForm!: FormDto;

  channel: ParticipantsModuleChannel | null = null;
  onlineUserCount = 0;
  participantCount = 0;
  participants: OnlineUserDto[] = [];

  async created() {
    this.channel = new ParticipantsModuleChannel({
      connection: this.$realtime,
      eventId: this.eventId,
      onOnlineUserCountUpdatedCallback: (count: number) => {
        this.onlineUserCount = count;
      },
      onMostRecentOnlineUsersUpdatedCallback: (users: OnlineUserDto[]) => {
        this.participants = users;
      },
    });
    this.channel.subscribe();

    const participantsData = await this.$api.getFormEntries({ formId: this.registrationForm.id, pageSize: 0 });
    this.participantCount = participantsData ? participantsData.totalCount : 0;
  }

  beforeDestroy() {
    this.channel?.unsubscribe();
  }

  public concatParticipantName({ firstName, lastName }: OnlineUserDto) {
    return `${firstName} ${lastName}`;
  }

  public onClickDirectMessage(user: OnlineUserDto) {
    if (user) this.$bus.emit('dm:modal-open', user);
  }
}
