import { EventHandler } from 'fourwaves-shared';

type RemoveListenerFn = () => void;

export type ScrollActionOptions = {
  duration?: number;
  offset?: number;
};

export interface IScrollContext {
  name: string | null;
  elements: Record<'scroll' | 'content', HTMLElement | null>;
  canScroll(): boolean;
  goTo(target?: number | Element, options?: ScrollActionOptions): Promise<void>;
  goToTop(options?: ScrollActionOptions): Promise<void>;
  goToBottom(options?: ScrollActionOptions): Promise<void>;
  addListener(handler: EventHandler<number>, immediate?: boolean): RemoveListenerFn;
  removeListener(handler: EventHandler<number>): void;
}

export interface ScrollContextInternalMethods {
  register(name: string, context: IScrollContext, key: string): void;
  remove(name: string, key: string): void;
}

export interface ScrollContextGlobal {
  (name?: string): IScrollContext | null;
}

export enum ScrollDirections {
  Up = 'up',
  Down = 'down',
}
