export default function ({ $config, $auth, redirect, store }) {
  if (!$auth?.loggedIn) {
    const querystring = new URLSearchParams();
    querystring.append('returnTo', encodeURI(window.location.href));
    return redirect(`${$config.API_URL}/event-redirect/login?${querystring.toString()}`);
  }

  if (!store.getters['events/isParticipant'] && !store.getters['events/isOrganizer']) {
    const querystring = new URLSearchParams();
    querystring.append('eventId', store.state.events.currentEvent.id);
    return redirect(`${$config.API_URL}/event-redirect/dashboard/restricted?${querystring.toString()}`);
  }
}
