
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
import { PresentationElement } from 'fourwaves-shared/components';
import PosterCard from '~/components/poster/PosterCard.vue';

@Component({
  components: { PosterCard },
})
export default class PosterCarousel extends Vue {
  @Prop({ required: true }) readonly posters!: PresentationElement[];

  visibleCards = 1;
  transitioning = false;
  currentPosition = 0;
  transitionName = 'swipe-left';
  auto = true;

  get hasControls(): boolean {
    return this.posters.length > this.visibleCards;
  }

  get lastIndex() {
    if (!this.hasControls) return 0;
    return this.posters.length - this.visibleCards;
  }

  mounted() {
    if (this.posters.length > 1) {
      setInterval(() => this.autoSwipe(), 5500);
    }
  }

  @Watch('posters')
  onPostersChanged(newPosters: PresentationElement[], oldPosters: PresentationElement[]) {
    if (newPosters.length === oldPosters.length) return;
    this.currentPosition = 0;
  }

  public autoSwipe() {
    if (!this.auto) return;
    this.next();
  }

  public onControlNext() {
    if (this.auto) this.auto = false;
    this.next();
  }

  public onControlPrev() {
    if (this.auto) this.auto = false;
    this.prev();
  }

  public next() {
    if (this.transitioning) return;
    this.transitionName = 'swipe-left';
    this.transitioning = true;
    this.currentPosition = this.currentPosition >= this.lastIndex ? 0 : this.currentPosition + 1;
    this.transitioning = false;
  }

  public prev() {
    if (this.transitioning) return;
    this.transitionName = 'swipe-right';
    this.transitioning = true;
    this.currentPosition = !this.currentPosition ? this.lastIndex : this.currentPosition - 1;
    this.transitioning = false;
  }
}
