
import { Component, Vue, namespace } from 'nuxt-property-decorator';
import type { Location } from 'vue-router';
import type { EventDto, ScheduleDto } from 'fourwaves-shared';
import { UserProfilePicture } from 'fourwaves-shared/components';
import LocaleLink from '~/components/common/LocaleLink.vue';

const EventsModule = namespace('events');

@Component({
  components: { LocaleLink, UserProfilePicture },
})
export default class Greetings extends Vue {
  @EventsModule.State sessions!: ScheduleDto[];
  @EventsModule.State currentEvent!: EventDto;
  @EventsModule.Getter nextBookmarkedSessionDate!: string | null;
  @EventsModule.Getter nextRelevantSessionDate!: string | null;

  get sessionCount(): number {
    return this.sessions.filter(({ isBookmarkedByCurrentUser }) => isBookmarkedByCurrentUser).length;
  }

  get bookmarkedSessionLink(): Location {
    if (!this.nextBookmarkedSessionDate && !this.nextRelevantSessionDate)
      return { name: 'slug-schedule', query: { isBookmarked: 'true' } };
    const date = this.nextBookmarkedSessionDate ? this.nextBookmarkedSessionDate : this.nextRelevantSessionDate;
    return { name: 'slug-schedule', query: { date: this.$moment(date).format('YYYY-MM-DD'), isBookmarked: 'true' } };
  }
}
