import type { UserDto } from 'fourwaves-shared';
import type { ThreadsChannel } from '~/channels/ThreadsChannel';

export namespace DirectMessaging {
  export interface User extends Partial<UserDto> {
    id: string;
    firstName: string | null;
    lastName: string | null;
  }

  export interface ThreadDto {
    id: string;
    users: User[];
    preview: string;
    lastUpdateDate: string;
    unreadMessagesCount: number;
  }

  export interface ProvisionalThread extends Partial<ThreadDto> {
    users: User[];
  }

  export interface State {
    threads: Record<string, ThreadDto>;
    channel: ThreadsChannel | null;
    isConnected: boolean;
    currentThreadId: string | null;
    provisionalThread: ProvisionalThread | null;
  }

  export enum Action {
    Init = 'init',
    OnThreadAdded = 'onThreadAdded',
    SetProvisionalThreadId = 'setProvisionalThreadId',
  }

  export enum Mutation {
    SetChannel = 'setChannel',
    SetConnectedState = 'setConnectedState',
    SetCurrentThreadId = 'setCurrentThreadId',
    SetThreads = 'setThreads',
    CreateProvisionalThread = 'createProvisionalThread',
    ClearProvisionalThread = 'clearProvisionalThread',
    PatchProvisionalThread = 'patchProvisionalThread',
    AddThread = 'addThread',
    PatchThread = 'patchThread',
  }
}
