
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { Location } from 'vue-router';

@Component
export default class LocaleLink extends Vue {
  @Prop({ default: null }) readonly to!: Location | null;
  @Prop({ default: null }) readonly replace!: Location | null;
  @Prop({ default: 'a' }) readonly tag!: string;
  @Prop(Boolean) readonly exact!: boolean;
  @Prop(Boolean) readonly exactPath!: boolean;

  get hasNoLink() {
    return this.to == null && this.replace == null;
  }

  get nuxtLinkProps() {
    if (this.hasNoLink) return {};
    const { to, replace, tag, exact, exactPath } = this;

    return !replace
      ? { to: this.localePath(to), tag, exact, exactPath }
      : { replace: this.localePath(replace), tag, exact, exactPath };
  }
}
