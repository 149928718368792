import { BaseChannel, BaseChannelParams } from 'fourwaves-shared';

export interface UserPresence {
  id: string;
  firstName: string;
  lastName: string;
  affiliation: string;
}

export interface PresenceDetails {
  totalCount: number;
  userSubset: UserPresence[];
}

interface OnPresenceUpdatePayload {
  channel: string;
  details: PresenceDetails;
}

type UserPresenceChannelParams = BaseChannelParams & {
  channel: string;
  onUpdateCallback: (details: PresenceDetails) => void;
};

export class UserPresenceChannel extends BaseChannel {
  private channel: string;
  private onUpdateCallback: (details: PresenceDetails) => void;

  constructor(params: UserPresenceChannelParams) {
    super(params.connection);
    this.channel = params.channel;
    this.onUpdateCallback = params.onUpdateCallback;
    this.subscribe();
  }

  subscribe() {
    if (this.isSubscribed) return;
    this.connection.on('OnPresenceUpdate', this.onUpdate);
    this.connection?.invoke('Subscribe', this.channel);
    this.isSubscribed = true;
  }

  unsubscribe() {
    if (!this.isSubscribed) return;
    this.connection.invoke('Unsubscribe', this.channel);
    this.connection.off('OnPresenceUpdate', this.onUpdate);
    this.isSubscribed = false;
  }

  onUpdate = (payload: OnPresenceUpdatePayload) => {
    if (payload.channel === this.channel) this.onUpdateCallback(payload.details);
  };
}
