
import { FormDto, FormEntryDto, getFirstName, getLastName, PaginatedList, ScheduleDto } from 'fourwaves-shared';
import { Component, Vue, namespace, Watch } from 'nuxt-property-decorator';

const EventsModule = namespace('events');

@Component({})
export default class PresentationsModule extends Vue {
  @EventsModule.Getter liveSessions!: ScheduleDto[];
  @EventsModule.Getter submissionForm!: FormDto;

  presentationsData: PaginatedList<FormEntryDto> | null = null;
  livePresentationsData: PaginatedList<FormEntryDto> | null = null;

  get presentationCount(): number {
    return this.presentationsData ? this.presentationsData.totalCount : 0;
  }

  get livePresentationCount(): number {
    return this.livePresentationsData ? this.livePresentationsData.totalCount : 0;
  }

  get featuredPresentations(): any[] {
    let data: FormEntryDto[] = [];

    if (this.presentationsData?.totalCount) {
      data = this.presentationsData.items;
    }

    if (this.livePresentationsData?.totalCount) {
      data = this.livePresentationsData.items;
    }

    return data.map(abstract => ({
      id: abstract.id,
      title: this.getSubmissionTitle(abstract, this.submissionForm),
      presenterName: `${getFirstName(abstract, this.submissionForm)} ${getLastName(abstract, this.submissionForm)}`,
    }));
  }

  @Watch('liveSessions', { immediate: true })
  async onLiveSessionsChanged(sessions: ScheduleDto[]) {
    this.presentationsData = await this.$api.getFormEntries({ formId: this.submissionForm.id, pageSize: 4 });
    this.livePresentationsData = sessions.length
      ? await this.$api.getFormEntries({
          formId: this.submissionForm.id,
          sessionIds: sessions.map(s => s.id),
          pageSize: 4,
        })
      : null;
  }
}
