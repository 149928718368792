
import { ActivityFeedEntryDto } from 'fourwaves-shared';
import { Component, Vue, Prop, Watch, namespace } from 'nuxt-property-decorator';
import PresentationBookmarked from './PresentationBookmarked.vue';
import ParticipantFollowed from './ParticipantFollowed.vue';
import PresentationComment from './PresentationComment.vue';
import ScrollContext from '~/components/layout/ScrollContext.vue';
import { ActivityFeedChannel } from '~/channels';

const EventsModule = namespace('events');
const LiveModule = namespace('live');

@Component({
  components: { ScrollContext },
})
export default class ActivityFeed extends Vue {
  @EventsModule.State eventId!: string;
  @LiveModule.Mutation setHasUnseenActivityFeedEntries!: (value: boolean) => void;

  @Prop({ type: Boolean, required: true }) readonly isDisplayed!: boolean;

  channel: ActivityFeedChannel | null = null;
  entries: ActivityFeedEntryDto[] = [];
  activityFeedComponents = {
    'presentation.bookmark': PresentationBookmarked,
    'participant.follow': ParticipantFollowed,
    'presentation.comment': PresentationComment,
  };

  @Watch('isDisplayed', { immediate: true })
  onDisplayChanged(isDisplayed: boolean) {
    if (!isDisplayed) return;
    this.setHasUnseenActivityFeedEntries(false);
  }

  created() {
    this.channel = new ActivityFeedChannel({
      connection: this.$realtime,
      eventId: this.eventId,
      onSyncCallback: (entries: ActivityFeedEntryDto[]) => {
        this.entries = entries;
      },
      onNewEntryCallback: this.onNewEntry,
    });
    this.channel.subscribe();
  }

  beforeDestroy() {
    this.channel?.unsubscribe();
  }

  public onNewEntry(entry: ActivityFeedEntryDto) {
    if (!this.isDisplayed) this.setHasUnseenActivityFeedEntries(true);
    this.entries.unshift(entry);
  }
}
