import * as signalR from '@microsoft/signalr';

interface VideoCallRequestEvent {
  eventId: string;
  abstractId: string;
  userId: string;
  firstName: string;
  lastName: string;
}

export async function initiateWebSocket(
  config: { API_URL: string; imgixDomain: string },
  accessToken: string,
  eventId: string,
): Promise<signalR.HubConnection> {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${config.API_URL}/hubs/realtime?eventId=${eventId}`, {
      accessTokenFactory: () => accessToken.replace('Bearer ', ''),
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Warning)
    .build();

  connection.on('VideoCallRequest', (payload: VideoCallRequestEvent) =>
    notifyVideoCallRequest(payload, config.imgixDomain),
  );

  async function startListening() {
    try {
      await connection.start();
    } catch (err) {
      console.error(err);
      setTimeout(() => startListening(), 5000);
    }
  }

  await startListening();
  return connection;
}

function notifyVideoCallRequest(request: VideoCallRequestEvent, imgixDomain: string) {
  if (!window || !window.$nuxt) return;

  const title = window.$nuxt.$t('conferencing.video_call_request_title');
  const name = `${request.firstName} ${request.lastName}`;
  const joinActionText = window.$nuxt.$t('conferencing.video_call_request_join') as string;
  const declineActionText = window.$nuxt.$t('conferencing.video_call_request_decline') as string;
  const userPictureSrc = `${imgixDomain}/static/media/profilepictures/${request.userId}/latest`;

  window.$nuxt.$toast.show(
    `
        <div class='video-call-request__msg-wrapper' role='alert'>
           <div class='video-call-request__profile-picture' aria-hidden='true'>
                    <img src='${userPictureSrc}' onerror="src='/images/user-profile-fallback.svg'"/>
            </div>
            <div class='video-call-request__details'>
                 <p class="video-call-request__name">${name}</p>
                <p class="video-call-request__title">${title}</p>
            </div>
        </div>
    `,
    {
      containerClass: '-video-call-request',
      className: 'video-call-request',
      duration: undefined,
      action: [
        {
          text: joinActionText,
          class: 'video-call-request__btn button -small',
          onClick: (_, toastObject) => {
            toastObject.goAway(0);
            let onAbstractPage = false;
            if (window.$nuxt.$route.name && window.$nuxt.$route.name.includes('slug-presentations-id')) {
              onAbstractPage = window.$nuxt.$route.params.id === request.abstractId;
            }

            if (onAbstractPage) {
              window.$nuxt.$store.dispatch('conferencing/setConferencingOpen', true);
            } else {
              window.$nuxt.$router.push(
                window.$nuxt.localePath({
                  name: 'slug-presentations-id',
                  params: { id: request.abstractId },
                  query: { join: 1 },
                }),
              );
            }
          },
        },
        {
          text: declineActionText,
          class: 'video-call-request__btn button -dark -small',
          onClick: (_, toastObject) => {
            toastObject.goAway(0);
          },
        },
      ],
    },
  );
}
