
import RoomCta from 'fourwaves-shared/src/components/blocks/RoomCta.vue';
import { Dictionary, EventDto, RoomDto, ScheduleDto, normalizeQuery, parseDateTime } from 'fourwaves-shared';
import { Component, Vue, namespace } from 'nuxt-property-decorator';
import { Location } from 'vue-router';

const EventsModule = namespace('events');

@Component({
  components: { RoomCta },
})
export default class SessionsModule extends Vue {
  @EventsModule.State currentEvent!: EventDto;
  @EventsModule.State currentTime!: string;
  @EventsModule.State sessions!: ScheduleDto[];
  @EventsModule.Getter liveSessions!: ScheduleDto[];
  @EventsModule.Getter nextRelevantSessionDate!: string;

  rooms: Dictionary<RoomDto> = {};

  get featuredSessions(): ScheduleDto[] {
    const now = this.$moment(this.currentTime);

    const liveOrUpcoming = this.sessions
      .filter(session => this.isLive(session) || this.$moment(session.startDate).isAfter(now))
      .slice(0, 3);
    if (liveOrUpcoming.length) return liveOrUpcoming;

    // fallback to the last 3 sessions when the event is over
    return this.sessions.slice(-3);
  }

  get isLiveLink(): Location {
    const parsedDate = this.nextRelevantSessionDate
      ? parseDateTime(this.nextRelevantSessionDate).format('YYYY-MM-DD')
      : '';
    const query = normalizeQuery({ date: parsedDate, isLive: true });
    return { name: 'slug-schedule', query };
  }

  async created() {
    const rooms = await this.$api.getRooms(this.currentEvent.id);
    this.rooms = Object.assign({}, ...rooms.map(room => ({ [room.id]: room })));
  }

  public isLive(session: ScheduleDto): boolean {
    return this.liveSessions.some(({ id }) => session.id === id);
  }
}
