
import { Component, Prop, Ref, Watch, mixins, namespace } from 'nuxt-property-decorator';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { orderBy, omit } from 'lodash-es';
import {
  MessageDto,
  MessagingChannel,
  MessageThreadType,
  MessageReactionType,
  validateResponse,
  isValidationError,
} from 'fourwaves-shared';
import { UserProfilePicture } from 'fourwaves-shared/components';
import DirectMessagingMixin from './DirectMessagingMixin';
import DirectMessagingChatInput from './DirectMessagingChatInput.vue';
import DirectMessagingChatEntry from './DirectMessagingChatEntry.vue';
import { ScrollContext } from '~/components';
import { DirectMessaging as DM } from '~/types';

const EventsModule = namespace('events');

type GroupedMessageList = MessageDto[][];
type GroupedMessageAccumulator = { lastUser: string; groups: GroupedMessageList };

const DirectMessagingModule = namespace('direct-messaging');
const pageSize = 25;

@Component({
  components: {
    ScrollContext,
    DirectMessagingChatInput,
    DirectMessagingChatEntry,
    UserProfilePicture,
    InfiniteLoading,
  },
})
export default class DirectMessagingChat extends mixins(DirectMessagingMixin) {
  @EventsModule.State eventId!: string;
  @DirectMessagingModule.State readonly threads!: Record<string, DM.ThreadDto>;
  @DirectMessagingModule.State readonly provisionalThread!: DM.ProvisionalThread;
  @DirectMessagingModule.Action(DM.Action.SetProvisionalThreadId) setProvisionalThreadId!: (id: string) => void;

  @Prop({ required: true }) readonly threadId!: string | null;

  @Ref() readonly scrollContext?: ScrollContext;

  channel: MessagingChannel | null = null;
  messages: Record<string, MessageDto> = {};
  hasUnreadMessages = false;
  hasReceivedUpdate = false;
  isFirstLoadCompleted = false;
  isInterlocutorOnline = false;
  pageNumber = 1;

  get orderedMessages() {
    return orderBy(Object.values(this.messages), [({ creationDate }) => creationDate], ['asc']);
  }

  get groupedMessages(): GroupedMessageList {
    const groupReducer = ({ lastUser, groups }: GroupedMessageAccumulator, msg: MessageDto) => {
      const lastGroup = lastUser !== msg.userId || !groups.length ? [] : groups.pop()!;
      return { lastUser: msg.userId, groups: [...groups, [...lastGroup, msg]] };
    };

    const { groups } = this.orderedMessages.reduce(groupReducer, { lastUser: '', groups: [] });
    return groups;
  }

  get thread() {
    if (this.provisionalThread) return this.provisionalThread;
    return this.threadId ? this.threads[this.threadId] : null;
  }

  get interlocutorUser() {
    if (this.provisionalThread) return this.getInterlocutorUser(this.provisionalThread.users);
    return this.thread ? this.getInterlocutorUser(this.thread.users) : null;
  }

  get token(): string {
    return this.threadId ? `${this.threadId}.${MessageThreadType.Direct}` : '';
  }

  get messageCount() {
    return Object.keys(this.messages).length;
  }

  @Watch('threadId', { immediate: true })
  onThreadIdChanged() {
    if (this.provisionalThread || !this.threadId || !this.thread) return;
    this.channel?.unsubscribe();

    this.channel = new MessagingChannel({
      connection: this.$realtime,
      objectId: this.threadId,
      type: MessageThreadType.Direct,
      onMessageAddedCallback: this.onMessageAdded,
      onMessageUpdatedCallback: this.onMessageUpdated,
      onMessageDeletedCallback: this.onMessageDeleted,
      onMessagesDeletedCallback: this.onMessagesDeleted,
      onMessageReactionAddedCallback: this.onMessageReactionAdded,
      onMessageReactionDeletedCallback: this.onMessageReactionDeleted,
    });

    this.channel.subscribe();
    this.fetchMessages();
  }

  mounted() {
    this.markThreadAsRead();
  }

  beforeDestroy() {
    this.channel?.unsubscribe();
  }

  public async fetchMessages($liveChatState?: StateChanger) {
    const scrollElement = this.scrollContext?.elements.scroll;
    const initialScrollHeight = scrollElement?.scrollHeight || 0;
    const response = await this.$api.getThreadMessages(this.threadId!, this.pageNumber, pageSize);
    if (this.pageNumber > 1) this.hasReceivedUpdate = true;

    if (!response.items.length) {
      $liveChatState?.complete();
      this.isFirstLoadCompleted = true;
      return;
    }

    this.messages = response.items.reduce(
      (messageMap, message) => ({ ...messageMap, [message.id]: message }),
      this.messages,
    );

    await this.$nextTick();
    this.isFirstLoadCompleted = true;
    $liveChatState?.loaded();
    if (response.totalCount <= this.messageCount) $liveChatState?.complete();
    this.pageNumber += 1;
    const completedScrollHeight = scrollElement?.scrollHeight || 0;
    const scrollHeightDiff = completedScrollHeight - initialScrollHeight;
    await this.$nextTick();
    if (this.scrollContext && scrollHeightDiff > 0) this.scrollContext.goTo(scrollHeightDiff);
  }

  public markThreadAsRead() {
    if (this.threadId && this.thread?.unreadMessagesCount) this.$api.markThreadAsRead(this.threadId);
  }

  public async sendMessage(text: string) {
    const thread = this.thread?.id ? this.thread : await this.createThread();
    if (!thread || !thread.id) return;
    const response = await this.$api.createMessage(thread.id, text);
    if (isValidationError(response)) this.feedErrorBag(response);
    this.scrollContext?.goToBottom({ duration: 80 });
    return response;
  }

  public async createThread() {
    if (!this.provisionalThread) return;

    const createThreadResponse = await this.$api.createDirectThread(
      MessageThreadType.Direct,
      this.provisionalThread.users.map(({ id }) => id),
    );

    const thread = validateResponse(createThreadResponse);

    if (!thread) {
      if (isValidationError(createThreadResponse)) this.feedErrorBag(createThreadResponse);
      return;
    }

    this.setProvisionalThreadId(thread.id);
    return thread;
  }

  public async onMessageAdded(token: string, message: MessageDto) {
    if (token !== this.token) return;
    this.markThreadAsRead();
    message.reactions = message.reactions || [];
    this.messages = { ...this.messages, [message.id]: message };
    if (message.userId !== this.$auth.user.id) this.hasUnreadMessages = true;
    await this.$nextTick();
    if (this.scrollContext && this.scrollContext.isAtBottom) this.scrollContext.goToBottom();
  }

  public onMessageUpdated(message: MessageDto) {
    if (!this.messages[message.id]) return;
    const { upvoteCount, isUpvotedByCurrentUser, messages, reactions } = this.messages[message.id];
    this.messages[message.id] = { ...message, upvoteCount, isUpvotedByCurrentUser, messages, reactions };
  }

  public onMessageDeleted(messageId: string) {
    this.messages = omit(this.messages, [messageId]);
  }

  public onMessageReactionAdded(messageId: string, type: MessageReactionType, userId: string) {
    const message = this.messages[messageId];
    if (!message) return;
    message.reactions = [...message.reactions, { type, userId }];
  }

  public onMessageReactionDeleted(messageId: string, reactionType: MessageReactionType, reactionUserId: string) {
    const message = this.messages[messageId];
    if (!message) return;

    message.reactions = message.reactions.filter(
      ({ type, userId }) => reactionType !== type || reactionUserId !== userId,
    );
  }

  public onMessagesDeleted(token: string) {
    if (this.token === token) this.messages = {};
  }

  public onInterlocutorStatusChanged(isOnline: boolean) {
    this.isInterlocutorOnline = isOnline;
  }

  public getAppearDelay(groupIndex: number, messageIndex: number, messagesInGroup: number) {
    return this.hasReceivedUpdate
      ? (messagesInGroup - messageIndex - 1) * 25
      : (this.groupedMessages.length - groupIndex - 1) * 50 + (messagesInGroup - messageIndex - 1) * 25;
  }
}
