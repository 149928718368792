
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class SplashScreen extends Vue {
  @Prop(Boolean) readonly forceDisplaySplash!: boolean;

  isSplashVisible = false;

  @Emit() splashComplete() {}

  async mounted() {
    await this.$nextTick();
    if (process.env.NODE_ENV !== 'development' || this.forceDisplaySplash) this.isSplashVisible = true;
    else this.splashComplete();
  }
}
