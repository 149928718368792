const OT = () => import('@opentok/client');

export async function getAvailableDevices(): Promise<OT.Device[]> {
  return await new Promise((resolve, reject) => {
    OT().then(t =>
      t.getDevices((error, devices) => {
        if (error) {
          return reject(error);
        }
        return devices ? resolve(devices) : resolve([]);
      }),
    );
  });
}
