
import { Component, Vue, namespace } from 'nuxt-property-decorator';
import { ActivityFeed } from '~/components/activity-feed';
import { GlobalChat } from '~/components/global-chat';

enum SidePanelTab {
  Feed = 'feed',
  Chat = 'chat',
}

const LiveModule = namespace('live');
const EventsModule = namespace('events');

@Component({
  components: { ActivityFeed, GlobalChat },
})
export default class LiveSidePanel extends Vue {
  @EventsModule.State eventId!: string;
  @EventsModule.State isSidePanelOpen!: boolean;
  @LiveModule.State hasUnseenActivityFeedEntries!: boolean;
  @LiveModule.State hasUnseenLiveChatMessages!: boolean;

  readonly SidePanelTab = SidePanelTab;
  activeTab: SidePanelTab = SidePanelTab.Chat;

  get isFeedActive() {
    return this.activeTab === SidePanelTab.Feed;
  }

  get isChatActive() {
    return this.activeTab === SidePanelTab.Chat;
  }
}
