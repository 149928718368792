
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FormDto, PageDto, FormFieldDto, transl8, FormFieldType, InputSelectOption } from 'fourwaves-shared';
import { FilterMultiSelect, FilterToggle, FilterBar, MobileFilterBar } from 'fourwaves-shared/components';

@Component({
  components: {
    FilterMultiSelect,
    FilterToggle,
    FilterBar,
    MobileFilterBar,
  },
})
export default class ParticipantFilterBar extends Vue {
  @Prop({ required: true }) readonly page!: PageDto | null;
  @Prop({ required: true }) readonly form!: FormDto | null;
  @Prop({ required: true }) readonly totalCount!: number;
  @Prop(Boolean) readonly areFiltersCollapsed!: boolean;

  get filters(): FormFieldDto[] {
    if (!this.page || !this.page.formFields.length) return [];

    return this.form!.formSections.flatMap(({ formFields }) => formFields)
      .filter(field => this.page!.formFields.find(({ id }) => id === field.id))
      .filter(
        field =>
          field.fieldType === FormFieldType.Radios ||
          field.fieldType === FormFieldType.Checkboxes ||
          field.fieldType === FormFieldType.Dropdown,
      );
  }

  get isLoggedIn() {
    return this.$auth.loggedIn;
  }

  get hasAppliedFilters() {
    const { page, layout, ...query } = this.$route.query;
    return Object.keys(query).length > 0;
  }

  public getFilterOptions(field: FormFieldDto): InputSelectOption[] {
    return field.formFieldChoices.map(({ id, shortLabel }) => ({ id, label: transl8(shortLabel) }));
  }
}
