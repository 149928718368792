import VueRouter from 'vue-router';
import UserActivitiesManager from 'fourwaves-shared/src/services/user-activities/manager';
import { BaseApiService } from 'fourwaves-shared/src/services/fourwaves/baseApiService';

export type NuxtAppWithUserActivitiesManager = {
  app: {
    $activities: UserActivitiesManager;
    $api: BaseApiService;
    router: VueRouter;
    store: any;
  };
};

export default ({ app }: NuxtAppWithUserActivitiesManager, inject) => {
  inject('activities', new UserActivitiesManager(app.$api, 'live'));

  app.router.afterEach((to, from) => {
    const path = to.path.replace(`/${to.params.slug}`, '');

    // do not process lang redirects
    if (to.name?.split('___')[0] === from.name?.split('___')[0]) return;

    // do not process redirections
    if (path === '/redirection') return;

    if (path) {
      const eventId = app.store.state.events.currentEvent?.id;
      if (eventId) {
        app.$activities.send('page.view', { eventId, reference: path });
      }
    }
  });
};
