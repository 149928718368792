import Vue from 'vue';
import { IScrollContext, ScrollContextInternalMethods, ScrollActionOptions } from '~/types/scroll-context';

type KeyedContextRecord = Record<string, { key: string; context: IScrollContext }>;

export default () => {
  const scrollContextVm = new Vue({
    data: () => <{ contexts: KeyedContextRecord }>{ contexts: {} },
    methods: {
      getInternalMethods(): ScrollContextInternalMethods {
        return {
          register: (name, context, key) => {
            this.$set(this.contexts, name, { key, context });
          },
          remove: (name, key) => {
            if (!this.contexts[name] || this.contexts[name].key !== key) return;
            this.$delete(this.contexts, name);
          },
        };
      },
    },
  });

  Vue.mixin({
    inject: {
      $scrollParent: { from: 'scrollContext', default: null },
    },
    computed: {
      _scrollContextInternal() {
        return scrollContextVm.getInternalMethods();
      },
    },
    methods: {
      $scrollContext(name?: string): IScrollContext | null {
        if (!name || !scrollContextVm.contexts[name]) return null;
        return !name ? this.$scrollParent : scrollContextVm.contexts[name].context || null;
      },
      $scrollTo(element: string | Element, options?: ScrollActionOptions) {
        const target = typeof element === 'string' ? document.querySelector(element) : element;
        if (target && this.$scrollParent) this.$scrollParent.goTo(target, options);
      },
    },
  });
};
