export default {
  livestreaming: 'LIVE',
  back_to_schedule: 'Back to schedule',
  date_countdown_date: 'Live on {date} at {time} {timezone}',
  date_countdown_soon: 'Live at {time}',
  date_countdown_minutes: 'Live in {minutes} minute',
  date_countdown_seconds: 'Live in less than a minute',
  streaming_ended: 'The streaming ended.',
  live_chat: {
    title: 'Chat',
    textarea_placeholder: 'Type a message',
    reaction: 'React',
  },
};
