import { BaseChannel, BaseChannelParams, ActivityFeedEntryDto } from 'fourwaves-shared';

type ActivityFeedChannelParams = BaseChannelParams & {
  eventId: string;
  onNewEntryCallback: (entry: ActivityFeedEntryDto) => void;
  onSyncCallback: (entries: ActivityFeedEntryDto[]) => void;
};

export class ActivityFeedChannel extends BaseChannel {
  private eventId: string;
  private onNewEntryCallback: (entry: ActivityFeedEntryDto) => void;
  private onSyncCallback: (entries: ActivityFeedEntryDto[]) => void;

  constructor(params: ActivityFeedChannelParams) {
    super(params.connection);
    this.eventId = params.eventId;
    this.onNewEntryCallback = params.onNewEntryCallback;
    this.onSyncCallback = params.onSyncCallback;
  }

  subscribe() {
    if (this.isSubscribed) return;
    this.connection.on('ActivityFeedSync', this.onSyncCallback);
    this.connection.on('ActivityFeedEntryAdded', this.onNewEntryCallback);
    this.connection.invoke('SyncActivityFeed', this.eventId);
    this.isSubscribed = true;
  }

  unsubscribe() {
    if (!this.isSubscribed) return;
    this.connection.off('ActivityFeedSync', this.onSyncCallback);
    this.connection.off('ActivityFeedEntryAdded', this.onNewEntryCallback);
    this.isSubscribed = false;
  }
}
