import { conferencingMutations, conferencingActions, getDefaultConferencingState } from 'fourwaves-shared';
import { MutationTree, ActionTree } from 'vuex';
import { RootState, ConferencingState } from '~/types/state';

export const state = (): ConferencingState => getDefaultConferencingState();

export const mutations: MutationTree<ConferencingState> = {
  ...conferencingMutations,
};

export const actions: ActionTree<ConferencingState, RootState> = {
  ...conferencingActions,
};
