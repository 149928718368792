import Vue from 'vue';
import {
  generateUserFriendlyId,
  moneyMixins,
  dateMixins,
  addressMixins,
  validatorMixins,
  formEntryMixins,
  contentMixins,
  validationMixins,
  fileMixins,
} from 'fourwaves-shared';

export const mixinMethods = {
  // Shared mixins
  ...formEntryMixins,
  ...dateMixins,
  ...addressMixins,
  ...validatorMixins,
  ...moneyMixins,
  ...contentMixins,
  ...validationMixins,
  ...fileMixins,
  getUserFriendlyId: generateUserFriendlyId,
  getLoginUrl(email = ''): string {
    const querystring = new URLSearchParams();
    if (email) {
      querystring.append('email', encodeURI(email));
    }
    querystring.append('returnTo', encodeURI(window.location.href));
    return `${this.$config.API_URL}/event-redirect/login?${querystring.toString()}`;
  },
};

Vue.mixin({
  methods: mixinMethods,
});
